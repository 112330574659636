<template>
  <div class="app-container">
    <div class="spaced">
      <CrudTable
        ref="table"
        entity="invoicedatacheck"
        :columns="columns"
        :load-data-on-created="true"
        :allow-actions="false"
      />
    </div>
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';

export default {
  components: { CrudTable },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'invoiceNumber', label: 'hermes.invoiceNumber' },
        { field: 'hCost', label: 'hermes.invoiceCost' },
        { field: 'hSurcharge', label: 'hermes.surchargeCost' },
        { field: 'tCost', label: 'hermes.totalCost' },
        { field: 'hRev', label: 'hermes.invoiceRevenue' },
        { field: 'hRevSurcharge', label: 'hermes.surchargeRevenue' },
        { field: 'tRevenue', label: 'hermes.totalRevenue' }
      ]
    };
  }
};
</script>
